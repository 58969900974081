<template>
  <div class="registered">
    <!--    电子邮箱阶段-->
    <div class="main" v-bind:style="style.mainEmail">
      <div class="logo"></div>
      <div class="remind" v-bind:style="remind" v-text="remind_text"></div>
      <div class="logo_title">注册</div>
      <div class="from_Email" v-bind:style="style.email">
        <input v-model="from.Mail">
        <div class="title">电子邮箱</div>
      </div>
      <div class="from_Code" v-bind:style="style.code">
        <input v-model="from.Code">
        <div class="title">请输入验证码</div>
      </div>
      <div class="code_time" v-bind:style="style.codetime">{{ code_times }}秒后可重发</div>
      <button class="code_button" v-bind:style="style.codebutton" @click="Get_Email">重新获取验证码</button>
      <button @click="Get_Code" v-text="button_status"></button>
    </div>
    <!--    注册账户阶段-->
    <div class="main" v-bind:style="style.Up" style="height: 545px;margin-top: -325px">
      <div class="logo"></div>
      <div class="remind" v-bind:style="remind" v-text="remind_text"></div>
      <div class="logo_title">注册</div>
      <div class="from_Name">
        <input v-model="from.Name">
        <div class="title">姓名</div>
      </div>
      <div class="from_Password">
        <input v-model="from.Password" type="password">
        <div class="title">密码</div>
      </div>
      <div class="from_Passwords">
        <input v-model="from.Passwords" type="password">
        <div class="title">确认密码</div>
      </div>
      <div class="from_Codes">
        <input v-model="from.Code">
        <div class="title">验证码</div>
      </div>
      <div class="code_img" @click="Get_Codeimg" v-html="codeimg"></div>
      <button @click="Get_In">注册</button>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>

import Footer from "@/components/pc/Footer";

export default {
  data() {
    return {
      button_status: '发送验证码',
      code_times: 0,
      codeimg: '',
      remind: '',
      remind_text: '',
      data: {
        svgImg: '',
        svgData: ''
      },
      style: {
        Up: 'display:none',
        mainEmail: 'display:block',
        code: '',
        email: 'display:block',
        codetime: '',
        codebutton: ''
      },
      from: {
        Password: '',
        Passwords: '',
        Name: '',
        SvgData: '',
        Code: '',
        Mail: '',
      }
    }
  },
  methods: {
    close() {
      this.from.Mail = '';
      this.from.Password = '';
    },
    remind_success(text) {
      this.remind = 'height: 20px;background-color: rgba(89,199,93,0.78)'
      this.remind_text = text;
      setTimeout(() => {
        this.remind = ''
      }, 1200)
    },
    remind_failure(text) {
      this.remind = 'height: 20px;background-color: rgba(143,10,6,0.78)'
      this.remind_text = text;
      setTimeout(() => {
        this.remind = ''
        this.remind_text = '';
      }, 2000)
    },
    Get_Email() {
      if (this.code_times == 0) {
        this.Get_Emails();
        this.style.codebutton = 'display:block;';
        this.code_time()
      }
    },
    Get_In() {
      if (this.from.Name&&this.from.Mail&&this.from.Password&&this.from.Passwords&&this.from.Code){
        if (this.from.Password==this.from.Passwords){
          if (this.from.SvgData!==this.from.Code){
            this.remind_failure('验证码错误')
            return ;
          }
          this.$http.get(process.env.VUE_APP_URL + 'admin/user/add',{
            params:{
              Name:this.from.Name,
              Email:this.from.Mail,
              password:this.from.Password
            }
          }).then(str => {
            if (str.data.sum=="ok"){
              this.remind_success('注册成功')
              setTimeout(() => {
                if (this.$route.query.goto) {
                  this.$router.push({
                    path: this.$route.query.goto
                  })
                } else {
                  this.$router.push({
                    path: ''
                  })
                }
              }, 2000)
            }else {
              this.remind_failure(str.data.sum)
            }
          })
        }else {
          this.remind_failure('密码与确认密码不一致')
        }

      }else {
        this.remind_failure('所有项必填')
      }


    },
    Get_Codeimg() {
      this.$http.get(process.env.VUE_APP_URL + 'admin/svg/verification').then(str => {
        let img = str.data.svgImg;
        this.codeimg = img;
        this.from.SvgData = str.data.svgData;
      })
    },
    Get_Emails() {
      let Mail = this.from.Mail;
      this.$http.get(process.env.VUE_APP_URL + 'admin/login/mail/add', {
        params: {
          email: Mail
        }
      }).then();
    },
    Get_Code() {
      if (this.button_status == "验证") {
        let Mail = this.from.Mail;
        let Code = this.from.Code;
        // 603551260@qq.com
        this.$http.get(process.env.VUE_APP_URL + 'admin/login/mail/check', {
          params: {
            email: Mail,
            code: Code
          }
        }).then(str => {
          let sum=str.data.sum;
          if (sum=="ok"){
            this.remind_success('验证成功');
            setTimeout(()=>{
              this.style.mainEmail='display:none';
              this.style.Up='display:block';
              this.from.Code=''
              this.Get_Codeimg();
              return ;
            },2000)
          }else {
            this.remind_failure(sum);
          }
        });
      } else {
        if (this.from.Mail) {
          let Mail = this.from.Mail;
          let reg = /^[a-z\d]+(\.[a-z\d]+)*@([\da-z](-[\da-z])?)+(\.{1,2}[a-z]+)+$/
          if (reg.test(Mail)) {
            this.Get_Emails();
            this.remind_success('邮件已成功发送');
            setTimeout(() => {
              this.style_clone();
              this.style.code = 'display:block';
              this.style.mainEmail = 'height:350px;display:block;';
              this.button_status = '验证';
              this.style.codetime = 'display:block';
              this.style.codebutton = 'display:block';
              this.code_time();
            }, 2000)

          } else {
            this.remind_failure('邮件地址格式错误')
          }
        } else {
          this.remind_failure('邮件地址未填写')
        }
      }
    },
    style_clone() {
      this.style.email = 'display:none';
      this.style.code = 'display:none';
    },
    code_time() {
      this.code_times = 60;
      this.codetimes = setInterval(() => {
        if (this.code_times == 0) {
          clearInterval(this.codetimes)
          this.style.codebutton = 'display:block;cursor: pointer;';
          return;
        }
        this.code_times -= 1;
      }, 1000)
    }
  },
  components: {
    Footer
  }
}
</script>

<style scoped lang="scss">
.registered {
  position: relative;
  width: 100%;
  height: 100%;

  .main {
    position: absolute;
    width: 400px;
    height: 280px;
    left: 50%;
    top: 50%;
    margin-top: -140px;
    margin-left: -200px;
    border-radius: 14px;
    border: 1px solid #29294f;
    overflow: hidden;
    display: none;

    .remind {
      color: #ffffff;
      text-align: center;
      font-size: 12px;
      width: 100%;
      height: 0;
      line-height: 20px;
      position: absolute;
      top: 70px;
      transition: 0.5s;
    }

    .logo {
      background-image: url("../../../assets/image/home/logo.png");
      width: 80px;
      height: 30px;
      background-size: 100% 100%;
      position: absolute;
      left: 30px;
      top: 15px;
    }

    .logo_title {
      position: absolute;
      left: 130px;
      top: 18px;
    }


    @mixin from_block {
      position: absolute;
      width: 360px;
      height: 45px;
      border-radius: 15px;
      border: 1px solid #5f6368;

      .title {
        position: absolute;
        background-color: #ffffff;
        left: 30px;
        top: -12px;
      }

      input {
        position: absolute;
        outline: none;
        border: none;
        width: 339px;
        height: 25px;
        left: 1px;
        border-radius: 15px;
        padding: 10px;
        font-size: 17px;
        transition: 0.2s;

        &:focus {
          left: -1px;
          top: -1px;
          border: 2px solid #29294f;
        }
      }


    }

    .from_Email {
      @include from_block;
      top: 120px;
      left: 20px;
      display: none;
    }

    .from_Name {
      @include from_block;
      top: 140px;
      left: 20px;
    }

    .from_Password {
      @include from_block;
      top: 230px;
      left: 20px;
    }

    .from_Passwords {
      @include from_block;
      top: 320px;
      left: 20px;
    }

    .from_Code {
      @include from_block;
      top: 140px;
      left: 20px;
      display: none;
    }


    .from_Codes {
      @include from_block;
      top: 400px;
      left: 20px;
      width: 159px;

      input {
        width: 138px;
      }
    }

    .code_img {
      position: absolute;
      width: 105px;
      height: 45px;
      right: 70px;
      top: 400px;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .code_time {
      display: none;
      position: absolute;
      bottom: 90px;
      left: 30px;
      font-size: 13px;
      color: #5f6368;
    }

    button {
      position: absolute;
      width: 360px;
      height: 40px;
      left: 50%;
      margin-left: -180px;
      bottom: 20px;
      border: none;
      outline: none;
      background: none;
      background-color: #203761;
      color: #ffffff;
      cursor: pointer;
      transition: 0.3s;
      border-radius: 10px;

      &:hover {
        background-color: #e4e7ed;
        color: #29294f;
      }
    }

    .code_button {
      display: none;
      width: 140px;
      left: 420px;
      bottom: 80px;
      cursor: no-drop;
    }
  }

  .Footer {
    position: initial;
    width: 100%;
    height: 40px;
  }
}
</style>
